import { DocumentTextIcon, ArrowLeftOnRectangleIcon, PlayIcon, StarIcon } from '@heroicons/react/24/solid'
import { NavLink } from '@remix-run/react'
import cx from 'classnames'
import React from 'react'

import MenuDropdown from './MenuDropdown'
import SearchBar from '~/modules/search/components/SearchBar'
import type { SignupStatus } from '~/modules/signup/types/signup-status'
import ContinueSignupNavLink from '~/modules/signup/components/ContinueSignupNavLink'
import PaymentIssueNavLink from '~/modules/signup/components/PaymentIssueNavLink'

interface HeaderProps {
  onClickLogout: () => void
  isLoggedIn: boolean
  signupStatus?: SignupStatus | null
}

const Header: React.FC<HeaderProps> = ({ onClickLogout, isLoggedIn }) => {
  return (
    <div className="z-20 bg-gradient-to-r from-basePurple via-basePink to-baseOrange pb-0.5">
      <header className="flex h-full items-center justify-between bg-black py-2 pl-2 pr-4 font-bold text-white md:py-3">
        <nav aria-label="Home and site search">
          <div className="flex items-stretch text-lg sm:text-2xl">
            <NavLink to="/" title="Pevan & Sarah" className="-my-2 mr-2 rounded-sm">
              <img className="h-12 w-auto md:h-14" src="/logo-text-dkbg-colour.svg" alt="Pevan and Sarah logo" />
            </NavLink>
            <SearchBar />
          </div>
        </nav>
        <nav aria-label="Main navigation" className="flex items-start text-sm md:text-base ">
          <ul className="flex items-center ">
            {!isLoggedIn && (
              <li className="ml-2 font-bold">
                <NavLink
                  to="/cub-club/login"
                  className={cx(
                    'flex items-center rounded-full bg-lightOrange px-3 py-1 text-dark transition-all duration-200 md:px-4',
                    'hover:bg-baseOrange hover:shadow-lg'
                  )}>
                  <ArrowLeftOnRectangleIcon className="h-4 w-4 md:h-5 md:w-5" />
                  <p className="ml-1 text-sm md:text-base">Log in</p>
                </NavLink>
              </li>
            )}

            <ContinueSignupNavLink />
            <PaymentIssueNavLink />

            <li className="ml-2 hidden font-bold sm:block">
              <NavLink to="/videos" className="flex">
                {({ isActive }) => (
                  <div
                    className={cx('flex items-center px-2 py-1 hover:text-baseOrange', {
                      'text-baseOrange': isActive
                    })}>
                    <PlayIcon className="h-5 w-5 md:h-6 md:w-6" />
                    <p className="ml-1 mt-0.5">Videos</p>
                  </div>
                )}
              </NavLink>
            </li>
            <li className="ml-2 hidden font-bold sm:block">
              <NavLink to="/printables" className="flex">
                {({ isActive }) => (
                  <div
                    className={cx('flex items-center px-2 py-1 hover:text-baseOrange', {
                      'text-baseOrange': isActive
                    })}>
                    <DocumentTextIcon className="h-5 w-5 md:h-6 md:w-6" />
                    <p className="ml-1 mt-0.5">Printables</p>
                  </div>
                )}
              </NavLink>
            </li>
            {isLoggedIn && (
              <li className="ml-2 hidden font-bold sm:block">
                <NavLink to="/cub-club/account/favourites" className="flex">
                  {({ isActive }) => (
                    <div
                      className={cx('flex items-center px-2 py-1 hover:text-baseOrange', {
                        'text-baseOrange': isActive
                      })}>
                      <StarIcon className="h-5 w-5 md:h-6 md:w-6" />
                      <p className="ml-1 mt-0.5">Favourites</p>
                    </div>
                  )}
                </NavLink>
              </li>
            )}
            <MenuDropdown onClickLogout={onClickLogout} isLoggedIn={isLoggedIn} />
          </ul>
        </nav>
      </header>
    </div>
  )
}

export default Header
