import { useLocation, NavLink } from '@remix-run/react'
import cx from 'classnames'

import useRootData from '~/hooks/useRootData'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'

const navLinkClasses = cx(
  'flex items-center rounded-full bg-lightPink px-3 py-1 text-dark transition-all duration-200 md:px-4',
  'hover:bg-basePink hover:shadow-lg'
)

export const PaymentIssueNavLink = () => {
  const location = useLocation()
  const { currentSubscription, currentCustomerAccessPermission } = useRootData() || {}
  const { isCustomerAdmin } = currentCustomerAccessPermission || {}

  const isSubscriptionDetailsPage = ['/cub-club/account/subscription'].some(path => location.pathname.startsWith(path))

  if (currentSubscription?.subscriptionStatus !== 'past_due' || isSubscriptionDetailsPage) {
    return null
  }

  if (isCustomerAdmin) {
    return (
      <li className="ml-2 font-bold">
        <NavLink to="/cub-club/account/subscription" className={navLinkClasses}>
          <p className="ml-1 text-sm md:text-base">Account is Past Due</p>
        </NavLink>
      </li>
    )
  }

  return (
    <>
      <Dialog
        title="Payment Required"
        content={
          <p className="text-gray-700 dark:text-gray-300">
            To access Cub Club, please contact your P&S administrator to resolve payment issues.
          </p>
        }
        trigger={<Button intent="danger" text="Account is Past Due" />}
        position="central"
      />
    </>
  )
}

export default PaymentIssueNavLink
